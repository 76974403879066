[resize] {
    --borderColor: transparent;
}

/* horizontal */

[resize] .horizontal {
    cursor: col-resize;
    width: 18px;
    height: 100%;
    bottom: 0;
    display: flex;
}

[resize] .horizontal.normal {
    right: -18px;
}

[resize] .horizontal.normal::after{
    position: absolute;
    content: '';
    height: 100%;
    right: 8px;
    border-right: transparent solid 3px;
    border-right-color: var(--borderColor);
    transition: border-right 0.3s ease-in 0s;
}

[resize] .horizontal.invert {
    left: 0px;
}

[resize] .horizontal.invert::after{
    position: absolute;
    content: '';
    height: 100%;
    left: 8px;
    border-left: transparent solid 3px;
    border-left-color: var(--borderColor);
    transition: border-left 0.3s ease-in 0s;
}

/* vertical */

[resize] .vertical {
    cursor: row-resize;
    width: 100%;
    height: 8px;
    right: 0;
    display: flex;
}

[resize] .vertical.normal {
    bottom: -18px;
}

[resize] .vertical.normal::after{
    position: absolute;
    content: '';
    height: 8px;
    top: 8px;
    width: 100%;
    border-bottom: transparent solid 3px;
    border-bottom-color: var(--borderColor);
    transition: border-bottom 0.3s ease-in 0s;
}

[resize] .vertical.invert {
    top: -8px;
}

[resize] .vertical.invert::after{
    position: absolute;
    content: '';
    height: 8px;
    width: 100%;
    top: 2px;
    border-top: transparent solid 3px;
    border-top-color: var(--borderColor);
    transition: border-top 0.3s ease-in 0s;
}